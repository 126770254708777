<template>
	<div>
		<div class="flex">
			<div class="margin-right-10">
				<router-link :to="{path:'/talents_detail',query:{user_id:info.user_id}}">
					<div class="head-box">
						<el-image :src="info.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
						<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="info.is_v==1">
					</div>
				</router-link>
				<!-- <img :src="info.head_img" class="block-48 radius-48"> -->
			</div>
			<div class="w100">
				<div class="flex align-center justify-between">
					<div class="flex align-center">
						<span class="fs9 margin-right-5">{{info.user_nickname}}</span>
						<div class="" v-if="show_star">
							<img src="../../assets/image/icon/star-fill.png" class="block-11" >
							<img src="../../assets/image/icon/star-gray.png" class="block-11" >
						</div>
					</div>
					<el-button type="text" class="text-theme" @click="deleteEvalue(info)" v-if="info.user_id==userInfo.id || writer_id==userInfo.id">删除</el-button>
				</div>
				<p class="margin-top-10 fs12" v-html="info.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)" v-if="info && info.content"></p>
				<div class="margin-top-10 flex align-center justify-between">
					<div class="flex align-center ">
						<p class=" fs9 text-999">{{info.create_time}}</p>
						<p class="fs9 margin-left-10 point text-blue" @click="reply(info)" v-if="can_reply">回复</p>
					</div>
					<div class="flex align-center point" @click="giveLike(info)">
						<transition :name=" info.is_like==1 ? 'zoom' : '' " mode="out-in">
							<img src="../../assets/image/home/zan1.png" class="block-22 margin-right-5" v-if="info.is_like==1" key="like">
							<img src="../../assets/image/home/zan.png" class="block-22 margin-right-5" v-else key="unlike">
						</transition>
						<span class="fs9">{{info.like_num || 0}}</span>
					</div>
				</div>
				<!-- <div class=" margin-top-15 padding-tb-15 padding-lr-10 bg-f6f7 radius-5 ovflowHidden">
					<textarea rows="6" cols="" class="w100 bg-f6f7 no-border" placeholder="说点什么吧"></textarea>
					<div class="flex align-center justify-end padding-top-15">
						<img src="../../assets/image/icon/smile.png" class="block-28">
						<span class="text-white bg-e54f  padding-lr-15 padding-tb-5 radius-6 margin-left-15">评论</span>
					</div>
				</div> -->
				<div class="padding-top-10 fs12" @click="openAll(info)" v-if="info.comment_list && info.comment_list.count>0">
					<span class="point">{{info.is_open ? '关闭' : '展开'}}{{info.comment_list.count}}条
						<i class="el-icon-arrow-down" v-if="info.is_open"></i>
						<i class="el-icon-arrow-up" v-if="!info.is_open"></i>
					</span>
				</div>
				<!-- <div class="padding-top-10" v-if="info.comment_list && info.comment_list.count>0"> -->
				<div class="padding-top-10" v-if="info.is_open">
					<div class="flex margin-top-10" v-for="(ele,j) in slist" :key="j">
						<router-link :to="{path:'/talents_detail',query:{user_id:ele.user_id}}">
							<div class="margin-right-10">
								<img :src="ele.head_img" class="block-28 radius-28">
							</div>
						</router-link>
						<div class="w100">
							<div class="flex align-center justify-between">
								<span class="fs9 margin-right-5 ">{{ele.user_nickname}}</span>
								<el-button type="text" class="text-theme" @click="deleteEvalue(ele)" v-if="info.user_id==userInfo.id || writer_id==userInfo.id">删除</el-button>
							</div>
							<p class="margin-top-10 fs9" v-html="ele.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)"></p>
							<div class="margin-top-10 flex align-center justify-between ">
								<div class="flex align-center ">
									<p class=" fs9 text-999">{{ele.create_time}}</p>
									<!-- <p class="fs9 margin-left-10 point" @click="reply(info)">回复</p> -->
								</div>
								<div class="flex align-center point" @click="giveLike(ele)">
									<transition :name=" ele.is_like==1 ? 'zoom' : '' " mode="out-in">
										<img src="../../assets/image/home/zan1.png" class="block-22 margin-right-5" v-if="ele.is_like==1" key="like">
										<img src="../../assets/image/home/zan.png" class="block-22 margin-right-5" v-else key="unlike">
									</transition>
									<!-- <img src="../../assets/image/home/zan1.png" class="block-18 margin-right-5" v-if="ele.is_like==1">
									<img src="../../assets/image/icon/like.png" class="block-18 margin-right-5" v-else> -->
									<span class="fs9">{{ele.like_num || 0}}</span>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class=" margin-top-15 padding-tb-15 padding-lr-10 bg-f6f7 radius-5 ovflowHidden  text-box">
						<textarea rows="6" cols="" class="w100 bg-f6f7 no-border" placeholder=""></textarea>
						<div class="top-txt">
						<span class="text-theme">回复</span>	 @ 用户名称
						</div>
						<div class="flex align-center justify-end padding-top-15">
							<img src="../../assets/image/icon/smile.png" class="block-28">
							<span
								class="text-white bg-e54f  padding-lr-15 padding-tb-5 radius-6 margin-left-15">评论</span>
						</div>
					</div> -->
				</div>
			</div>

		</div>
		<el-dialog title="" :visible.sync="dialogFormVisible">
		  <div class=" margin-top-15 padding-tb-15 padding-lr-10 bg-f6f7 radius-5 ovflowHidden  text-box">
			<span class="text-theme">回复</span>	 @ {{reply_name}}
		  	<textarea rows="6" cols="" autofocus="true" v-model="sComment" class="w100 bg-f6f7 no-border padding-left-40 padding-top-10" placeholder=""></textarea>
		  	<div class="top-txt">
		  	</div>
		  	<div class="flex align-center justify-end padding-top-15">
		  		<el-popover
		  		  placement="right"
		  		  width="400"
		  		  trigger="click">
		  		  <emotion @emotion="handleEmotion" :height="200" ref="emojiPop"></emotion>
		  		  <img slot="reference" src="../../assets/image/icon/smile.png" class="block-28 point">
		  		</el-popover>
		  		<span class="text-white bg-e54f  padding-lr-15 padding-tb-5 radius-6 margin-left-15 point" @click="secondReply">评论</span>
		  	</div>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import Emotion from '@/components/Emotion/index'
	export default {
		components: {
			Emotion
		},
		props: {
			// 是否展示星级
			show_star: [Boolean],
			// 数据
			info: [Array, Object],
			// 文章id
			aid:{
				type:[String,Number],
				default:''
			},
			// 作者id
			writer_id:{
				type:[String,Number],
				default:''
			},
			// 是否能回复
			can_reply:{
				type:Boolean,
				default:true
			}
		},
		data() {
			return {
				dialogFormVisible:false, //回复弹窗
				sid:'' ,//二级id
				slist:[] ,//二级评论列表
				reply_name:'', //回复人昵称
				sComment:'', //二级评论内容
			}
		},
		computed: {
			userInfo() {
				return this.$store.getters.userInfo ||{};
			}
		},
		created() {
			this.info.is_open = false
		},
		methods: {
			// 删除评论
			deleteEvalue(item){
				this.ajax('post', '/v1/6180f8dd77ddf', {
					id: item.id
				}, res => {
					if (res.code == 1) {
						this.$emit('refresh')
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 点赞
			giveLike(item){
				console.log(item);
				this.ajax('post', '/v1/6151782d5b389', {
					comment_id: item.id
				}, res => {
					if (res.code == 1) {
						item.is_like = item.is_like ==1 ? 0 : 1;
						if(item.is_like ==1){
							item.like_num +=1
						}else{
							item.like_num -=1
						}
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 回复评论
			reply(info) {
				console.log(info);
				this.reply_name = info.user_nickname;
				this.sid = info.id;
				this.dialogFormVisible = true
			},
			// 发布二级评论
			secondReply(){
				if(!this.sComment){
					this.$message.error('请先输入回复内容');
					return false;
				};
				this.ajax('post', '/v1/6151764c066ea', {
					document_id: this.aid,
					content:this.sComment,
					id:this.sid //回复谁传谁的id
				}, res => {
					if (res.code == 1) {
						this.$message.success('评论成功');
						this.sComment = '';
						this.dialogFormVisible = false;
						this.$emit('refresh')
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 关闭回复弹窗方法
			closeModal(){
				this.dialogFormVisible = false
			},
			// 展开评论
			openAll(info) {
				if (info.is_open == true) {
					this.$set(info, 'is_open', false)
				} else {
					this.$set(info, 'is_open', true)
				};
				this.sid = info.id;
				this.getCommentLists();
			},
			// 获取二级评论列表
			getCommentLists() {
				this.ajax('post', '/v1/61516d9c4dc60', {
					document_id:this.aid,
					id:this.sid //回复谁传谁的id
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.slist =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			handleEmotion (i) {
			  this.sComment += i
			},
			// 将匹配结果替换表情图片
			emotion (res) {
			  let word = res.replace(/\#|\;/gi,'')
			  const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
			  let index = list.indexOf(word)
			  return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`   
			}
		},

	}
</script>

<style scoped>
	.text-box{
		position: relative;
	}
	.top-txt {
		position: absolute;
		top: 20px;
		left: 20px;
	}
	.text-blue{
		color: rgb(65, 119, 221);
	}
	/** 动画进行时的class **/
	.zoom-enter-active, .zoom-leave-active {
	    transition: all .15s cubic-bezier(0.42, 0, 0.34, 1.55);
	}
	
	/** 设置进场开始的状态和离场结束的状态，都是缩放到0 **/
	.zoom-enter, .zoom-leave-to {
	    transform: scale(0);
	}
	
	/** 设置进场结束的状态和离场开始的状态, 都是缩放到1 **/
	.zoom-enter-to, .zoom-leave {
	    transform: scale(1);
	}
	.head-box{
		position: relative;
	}
	.bigv{
		position: absolute;
		bottom: 5px;
		right: 5px;
	}
</style>
